@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.form_control {
  position: relative;
  margin-bottom: 20px;
}

.form_label {
  display: block;
  margin-bottom: 5px;
}
.form_label_inline {
  display: inline-block;
}

.form_label,.checkbox_label,.form_label_inline {
  font-size: 18px;
  color: $grey-9;

  vertical-align: middle;
}

.text_input {
  width: 100%;
  padding: 10px;

  font-size: 16px;

  border: 1px solid #888;
  border-radius: 5px;
}

.q-text-input {
  font-size: 16px;
}

.q-field--outlined .q-field__control:before {
  border: 1px solid #888;
}