.container {
  padding: 30px;
}

.section {
  color: #424242;
  margin-bottom: 30px;
}

.section__title {
  padding-bottom: 8px;
  border-bottom: 1px solid #bdbdbd;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}

.section__content {
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.section__content_title {
  padding: 10px 0;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.section__content_text {
  padding: 30px 35px;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 32px;
}

.section__content_img {
  min-height: 200px;
  display: flex;
  padding: 0 35px 15px;
  align-items: flex-end;
}

.section_card_wrapper {
  padding: 10px;
}

.section_card_inner {
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
}

@media (max-width: 1024px) {
  .section__title {
    font-size: 28px;
  }

  .section_card_wrapper {
    padding: 15px 0;
  }
}
@media (max-width: 600px) {
  .container {
    padding: 20px 15px 0px;
  }

  .section__title {
    font-size: 22px;
  }

  .section__content_title {
    font-size: 18px;
  }

  .section__content_text {
    font-size: 16px;
    line-height: 28px;
  }
}