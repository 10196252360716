@charset "UTF-8";
body {
  font-family: "微軟正黑體", sans-serif;
  font-size: 16px;
}

ol {
  padding-inline-start: 18px;
}

ul > li {
  list-style: none;
}

a:link, a:visited {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

hr {
  border: 0.5px solid #e0e0e0;
}

.link {
  color: inherit;
}

.link:hover {
  color: #616161;
}

.overflow-visible {
  overflow: visible !important;
}

.btn {
  padding: 7px 10px;
  border-radius: 5px;
}

.btn-grey-outlined {
  color: #616161;
  border: 0.75px solid #757575;
  transition: all 0.2s;
}

.btn-grey-outlined:hover {
  background-color: #616161;
  color: white;
}

.link-light-grey {
  color: #616161;
}

.link-light-grey:hover {
  color: #212121;
}

.dialog_inner {
  position: relative;
}

.dialog_inner__close {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.white_box_shadow {
  background-color: white;
  box-shadow: 0 0 7px -2px #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.table_page_nav {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.q-table--bordered {
  border: 1px solid #ccc;
}

.table th {
  background-color: #424242;
  color: white;
  font-size: 16px;
}

.table tbody td {
  font-size: 16px;
  color: #424242;
}

/* 頁面 */
.banner_title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.ai_house_pic {
  width: 60px;
  height: auto;
  margin: 10px;
}

/* 自訂顏色 */
.text-sage {
  color: #BEB089;
}

.bg-sage {
  background-color: #BEB089 !important;
}

@media (max-width: 600px) {
  main {
    font-size: 14px;
  }

  .banner_title {
    font-size: 28px;
  }
}