.toolbar {
  white-space: nowrap;
  overflow-x: auto;
  z-index: 1;
}

.page_container {
  margin-bottom: 15px;
  padding: 25px 25px;
  border-radius: 5px;
  color: #424242;
  background-color: white;
}

.page_title {
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 26px;
  font-weight: bold;
}

.data_area {
  border: 1px solid #ccc;
}

.page_container__full_width {
  padding: 0;
  margin-bottom: 15px;
  border-radius: 5px;
  color: #424242;
  background-color: white;
  overflow: hidden;
}

.search_form_label {
  width: 100px;
  font-weight: bold;
  color: #424242;
  text-align: left;
}

.search_form_expansion_header {
  background-color: #424242;
  color: white;
}

.search_form_expansion_header__icon {
  color: white;
}