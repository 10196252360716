@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form

body {
  font-family: '微軟正黑體', sans-serif;
  font-size: 16px;
}

ol {
  padding-inline-start: 18px;
}

ul > li {
  list-style: none;
}

a:link, a:visited {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

hr {
  border: 0.5px solid $grey-4;
}

.link {
    color: inherit;
  }

.link:hover {
  color: $grey-8;
}

.overflow-visible {
  overflow: visible !important;
}

.btn {
  padding: 7px 10px;
  border-radius: 5px;
}

.btn-grey-outlined {
  color: $grey-8;
  border: 0.75px solid $grey-7;
  transition: all .2s;
}

.btn-grey-outlined:hover {
  background-color:$grey-8;
  color: white;
}

.link-light-grey {
  color: $grey-8;
}

.link-light-grey:hover {
  color: $grey-10;
}

.dialog_inner{
  position:relative;
}
.dialog_inner__close {
  position: absolute;
  z-index: 10;
  top:10px;
  right:10px;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.white_box_shadow {
  background-color: white;
  box-shadow: 0 0 7px -2px #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.table_page_nav {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.q-table--bordered {
  border: 1px solid #ccc;
}

.table th {
  background-color: $grey-9;
  color: white;
  font-size: 16px;
}

.table tbody td {
  font-size: 16px;
  color: $grey-9;
}



/* 頁面 */
.banner_title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.ai_house_pic {
  width: 60px;
  height: auto;

  margin: 10px;
}

/* 自訂顏色 */
.text-sage { color: $sage; }
.bg-sage { background-color: $sage !important; }

@media (max-width: $breakpoint-sm-min) {
  main {
    font-size: 14px;
  }
  .banner_title {
    font-size: 28px;
  }
}
